import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
	FlexColumnWrapper,
	FlexWrapper
} from '../../../shared/wrappers/styled-divs';
import { MatchingRoundTypeEnum } from '../../../../api-client';
import { convertToTargetTimezone } from '../../../../utils/convertToTargetTimezone';
import { AppState } from '../../../../AppModel';
import { MatchingRoundClassModel } from '../../../../models/group/matching-round/matching-round';
import { InviteRoundClassModel } from '../../../../models/group/invite-round/invite-round';
import { convertToLocalTimezone } from '../../../../utils/convertToLocalTimezone';
import { RoundPresetEnum } from '../schedule-intro-round-overlay-view-model';

/**
 * Interfaces the props for the add/edit round panel
 */
export interface IAddEditRoundPanelProps {
	/**
	 * The matching round to manage in the overlay
	 */
	matchingRound: MatchingRoundClassModel;
	/**
	 * The invite round to manage in the overlay
	 */
	inviteRound: InviteRoundClassModel;
	onPresetChange: (preset: RoundPresetEnum) => void;
}

/**
 * Enumize all recurrence settings
 */
export enum RecurrenceSettings {
	Weekly = 'Every Week',
	BiWeekly = 'Every Two Weeks',
	TriWeekly = 'Every Three Weeks',
	Monthly = 'Every Four Weeks',
	None = 'None'
}

/**
 * Enumize all round scheduling options
 */
export enum RoundSchedulingSetting {
	CALENDAR_INVITE = 'Send Calendar Invite w/ Intro',
	SCHEDULE_LINK = 'Send Scheduling Link w/ Intro',
	NONE = 'No Scheduling or Calendar Links'
}

export const AddEditRoundPanel = observer((props: IAddEditRoundPanelProps) => {
	const { matchingRound, inviteRound, onPresetChange } = props;

	const [selectedOption, setSelectedOption] = useState<string>('');

	/**
	 * Handle a preset change
	 */
	const handlePresetChange = (preset: string) => {
		onPresetChange(preset as RoundPresetEnum);
		// Calculate the next time at a 15 minute interval in the target timezone
		let next15MinDate: Date = new Date();
		next15MinDate.setMinutes(
			next15MinDate.getMinutes() + 15 - (next15MinDate.getMinutes() % 15)
		);
		next15MinDate = convertToLocalTimezone(
			next15MinDate,
			AppState.selectedGroup?.timezone
		);

		if (!matchingRound.time) {
			onMatchingRoundTimeChange(next15MinDate);
		}

		if (!inviteRound.time) {
			onInviteRoundTimeChange(next15MinDate);
		}

		// Switch case for each preset
		switch (preset) {
			case 'opt_in_w_scheduling':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_IN);
				onSelectedSchedulingSettingChange(
					RoundSchedulingSetting.SCHEDULE_LINK
				);
				break;
			case 'opt_in_w_out_scheduling':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_IN);
				onSelectedSchedulingSettingChange(RoundSchedulingSetting.NONE);
				break;
			case 'opt_in_predetermined':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_IN);
				onSelectedSchedulingSettingChange(
					RoundSchedulingSetting.CALENDAR_INVITE
				);
				break;
			case 'opt_out_w_scheduling':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_OUT);
				onSelectedSchedulingSettingChange(
					RoundSchedulingSetting.SCHEDULE_LINK
				);
				break;
			case 'opt_out_w_out_scheduling':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_OUT);
				onSelectedSchedulingSettingChange(RoundSchedulingSetting.NONE);
				break;
			case 'opt_out_predetermined':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_OUT);
				onSelectedSchedulingSettingChange(
					RoundSchedulingSetting.CALENDAR_INVITE
				);
				break;
			case 'no_invite_w_scheduling':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_OUT);
				onSelectedSchedulingSettingChange(
					RoundSchedulingSetting.SCHEDULE_LINK
				);
				onInviteRoundTimeChange(undefined);
				break;
			case 'no_invite_w_out_scheduling':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_OUT);
				onSelectedSchedulingSettingChange(RoundSchedulingSetting.NONE);
				onInviteRoundTimeChange(undefined);
				break;
			case 'no_invite_predetermined':
				onOptInStyleChange(MatchingRoundTypeEnum.OPT_OUT);
				onSelectedSchedulingSettingChange(
					RoundSchedulingSetting.CALENDAR_INVITE
				);
				onInviteRoundTimeChange(undefined);
				break;
		}
	};

	// Detect the selected option based on the matching round and invite round settings
	useEffect(() => {
		if (!matchingRound?.time) {
			setSelectedOption(undefined);
		} else if (inviteRound?.time) {
			if (matchingRound?.inviteType === MatchingRoundTypeEnum.OPT_IN) {
				if (matchingRound?.sendSchedulingLink) {
					setSelectedOption('opt_in_w_scheduling');
				} else if (matchingRound?.sendCalendarInvite) {
					setSelectedOption('opt_in_predetermined');
				} else {
					setSelectedOption('opt_in_w_out_scheduling');
				}
			} else if (
				matchingRound?.inviteType === MatchingRoundTypeEnum.OPT_OUT
			) {
				if (matchingRound?.sendSchedulingLink) {
					setSelectedOption('opt_out_w_scheduling');
				} else if (matchingRound?.sendCalendarInvite) {
					setSelectedOption('opt_out_predetermined');
				} else {
					setSelectedOption('opt_out_w_out_scheduling');
				}
			}
		} else {
			if (matchingRound?.sendSchedulingLink) {
				setSelectedOption('no_invite_w_scheduling');
			} else if (matchingRound?.sendCalendarInvite) {
				setSelectedOption('no_invite_predetermined');
			} else {
				setSelectedOption('no_invite_w_out_scheduling');
			}
		}
	}, [
		matchingRound?.inviteType,
		matchingRound?.sendCalendarInvite,
		matchingRound?.sendSchedulingLink,
		matchingRound?.time,
		inviteRound?.time
	]);

	/**
	 * Set the schedule setting for each matching round
	 */
	const onSelectedSchedulingSettingChange = (
		setting: RoundSchedulingSetting
	) => {
		matchingRound.updateSchedulingDetails({
			shouldSendCalendarInvite:
				setting === RoundSchedulingSetting.CALENDAR_INVITE ? 1 : 0,
			shouldSendSchedulingLink:
				setting === RoundSchedulingSetting.SCHEDULE_LINK ? 1 : 0,
			shouldSendCalendarHold: 0,
			duration: matchingRound.duration
		});
	};

	/**
	 * Set the value of the optin style for all matching rounds in the overlay
	 */
	const onOptInStyleChange = (optInStyle: MatchingRoundTypeEnum) => {
		matchingRound.setInviteType(optInStyle);
	};

	/**
	 * Handle a change in the matching round time
	 */
	const onMatchingRoundTimeChange = (time: Date) => {
		matchingRound.updateTime(
			convertToTargetTimezone(time, AppState.selectedGroup?.timezone)
		);
	};

	/**
	 * Handle a change in the invite round time
	 */
	const onInviteRoundTimeChange = (time: Date) => {
		if (time) {
			inviteRound?.updateTime(
				convertToTargetTimezone(time, AppState.selectedGroup?.timezone)
			);
			inviteRound?.setActive(true);
		} else {
			inviteRound?.clearDateTime();
			inviteRound?.setActive(false);
		}
	};

	return (
		<Container>
			<PanelSection>
				<ChooseFlowNotification>
					<ChooseFlowTitle>Choose a flow</ChooseFlowTitle>
					<ChooseFlowDescription>
						Please choose a flow from the list below to determine
						the member experience
					</ChooseFlowDescription>
				</ChooseFlowNotification>
				<SectionHeader>Opt-in Flows</SectionHeader>
				<SectionDescription>
					Members must opt-in to get an introduction
				</SectionDescription>
				<RadioPanelGroup
					options={[
						{
							key: 'opt_in_w_scheduling',
							label:
								'Opt-in with scheduling after an introduction',
							img:
								'/assets/svgs/new-intro-round/calendar-plus.svg'
						},
						{
							key: 'opt_in_w_out_scheduling',
							label:
								'Opt-in with no scheduling after an introduction',
							img: '/assets/svgs/new-intro-round/paper-plus.svg'
						},
						{
							key: 'opt_in_predetermined',
							label:
								'Opt-in for a pre-determined time to meet with your match',
							img:
								'/assets/svgs/new-intro-round/calendar-check.svg'
						}
					]}
					selectedOption={selectedOption}
					onChangeSelectedOption={handlePresetChange}
				/>
			</PanelSection>
			<PanelSection>
				<SectionHeader>Opt-out Flows</SectionHeader>
				<SectionDescription>
					Members are all default opted-in, with an option to opt-out
				</SectionDescription>
				<RadioPanelGroup
					options={[
						{
							key: 'opt_out_w_scheduling',
							label:
								'Opt-out with scheduling after an introduction',
							img:
								'/assets/svgs/new-intro-round/calendar-plus.svg'
						},
						{
							key: 'opt_out_w_out_scheduling',
							label:
								'Opt-out with no scheduling after an introduction',
							img: '/assets/svgs/new-intro-round/paper-plus.svg'
						},
						{
							key: 'opt_out_predetermined',
							label:
								'Opt-out for a pre-determined time to meet with your match',
							img:
								'/assets/svgs/new-intro-round/calendar-check.svg'
						}
					]}
					selectedOption={selectedOption}
					onChangeSelectedOption={handlePresetChange}
				/>
			</PanelSection>
			<PanelSection>
				<SectionHeader>No Opt-in or Opt-out</SectionHeader>
				<SectionDescription>
					Assumed all members will participate, and no opt-in or
					opt-out message is sent.
				</SectionDescription>
				<RadioPanelGroup
					options={[
						{
							key: 'no_invite_w_scheduling',
							label: 'Scheduling after an introduction',
							img:
								'/assets/svgs/new-intro-round/calendar-plus.svg'
						},
						{
							key: 'no_invite_w_out_scheduling',
							label: 'No scheduling after an introduction',
							img: '/assets/svgs/new-intro-round/paper-plus.svg'
						},
						{
							key: 'no_invite_predetermined',
							label:
								'Pre-determined time to meet with your match',
							img:
								'/assets/svgs/new-intro-round/calendar-check.svg'
						}
					]}
					selectedOption={selectedOption}
					onChangeSelectedOption={handlePresetChange}
				/>
			</PanelSection>
		</Container>
	);
});

interface RadioPanelGroupOption {
	key: string;
	label: string;
	img: string;
}

export interface IRadioPanelGroupProps {
	/**
	 * List of options
	 */
	options: RadioPanelGroupOption[];
	/**
	 * Selected option key
	 */
	selectedOption: string;
	/**
	 * Callback to run when the admin changes the selected options
	 */
	onChangeSelectedOption: (selectedOption: string) => void;
}

export const RadioPanelGroup = (props: IRadioPanelGroupProps) => {
	const { options, selectedOption, onChangeSelectedOption } = props;

	const selectOption = (option: RadioPanelGroupOption) => {
		onChangeSelectedOption(option.key);
	};

	const deselectOption = (option: RadioPanelGroupOption) => {
		onChangeSelectedOption(undefined);
	};

	return (
		<RadioPanelGroupContainer>
			{options.map((option: RadioPanelGroupOption) => {
				// determine if this option is selected
				const selected = selectedOption === option.key;

				return (
					<OptionBox
						selected={selected}
						onClick={
							selected
								? () => deselectOption(option)
								: () => selectOption(option)
						}
					>
						<TitleIconWrapper>
							<ImgIcon src={option.img} alt={option.label} />
						</TitleIconWrapper>
						<DescriptionIconWrapper>
							<DescriptionText>{option.label}</DescriptionText>
						</DescriptionIconWrapper>
					</OptionBox>
				);
			})}
		</RadioPanelGroupContainer>
	);
};

const OptionBox = styled.div<{ selected?: boolean }>`
	width: 100%;
	height: 64px;
	padding-left: 24px;
	padding-right: 24px;
	background: ${(props) => (props.selected ? '#FFFAEA' : 'white')};
	border-radius: 8px;
	border: ${(props) =>
		props.selected ? '1.5px #FDCF2B solid' : '1px #E8E8EB solid'};
	display: inline-flex;
	align-items: center;
	gap: 12px;
	cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
	box-sizing: border-box;
`;

const DescriptionText = styled.div`
	color: #151e34;
	font-size: 14px;
	font-family: Inter;
	font-weight: 600;
	word-wrap: break-word;
`;

const RadioPanelGroupContainer = styled.div`
	width: 100%;
	height: 100%;
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 12px;
`;

const TitleIconWrapper = styled(FlexWrapper)`
	gap: 16px;
	align-items: center;
`;

const ImgIcon = styled.img``;

const DescriptionIconWrapper = styled(FlexWrapper)`
	gap: 12px;
	align-items: center;
`;

const Container = styled(FlexColumnWrapper)`
	width: 100%;
	height: 100%;
	padding: 24px 24px 32px 24px;
	border-right: 1px solid var(--Main-15, #dcdde1);
	gap: 40px;
	overflow-y: scroll;
	box-sizing: border-box;
`;

const SectionHeader = styled.div`
	color: var(--Main-100, #151e34);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const SectionDescription = styled.div`
	color: var(--Main-60, #737885);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 6px;
	margin-bottom: 16px;
`;

const PanelSection = styled.div``;

const ChooseFlowNotification = styled(FlexColumnWrapper)`
	gap: 4px;
	padding: 16px;
	background: #ebe5fc;
	border-radius: 8px;
	margin-bottom: 24px;
	width: 100%;
	box-sizing: border-box;
`;

const ChooseFlowTitle = styled.div`
	color: var(--Main-100, #151e34);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const ChooseFlowDescription = styled.div`
	color: var(--Main-60, #444b5d);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
