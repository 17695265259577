import { ObservableMap, computed, makeObservable, observable } from 'mobx';
import { ISurveyModelParams, SurveyClassModel } from '../survey';
import { RestApiClient } from '../../api/rest/restApiClientModel';
import { map } from 'rxjs/operators';
import {
	DynamicQuestionModel,
	IDynamicQuestionModel
} from '../question/dynamic/dynamic-question';
import { IAbstractQuestionModel } from '../question/abstract-question';
import {
	StaticQuestionEnum,
	StaticQuestionModel
} from '../question/static/static-question';
import {
	ConditionalLogicModel,
	FixedSlidesEnum,
	QuestionVisibilityEnum,
	QuestionsService,
	SurveyQuestionResponse,
	SurveyTypes,
	UpdateQuestionPutRequest,
	UpdateQuestionPutResponse
} from '../../../api-client';
import { Observable } from 'rxjs';
import { IRadioSurveyQuestionModel } from '../question/dynamic/radio/radio-survey-question';
import { IFreetextSurveyQuestionModel } from '../question/dynamic/freetext/freetext-survey-question';
import { ILikertSurveyQuestionModel } from '../question/dynamic/likert/likert-survey-question';
import { ReadOnlyAnswer, SurveyQuestionTypeEnum } from '../question/types';
import { IMultiSelectSurveyQuestionModel } from '../question/dynamic/radio/multiselect/multiselect-survey-question';
import { ISurveyAnswerOptionModel } from '../answer/survey-answer-option';
import { IIntentionalSurveyQuestionModel } from '../question/dynamic/radio/multiselect/intentional/intentional-survey-question';

export interface IJoinSurveyModelParams extends ISurveyModelParams {
	/**
	 * Index for the email question
	 */
	emailIndex: number;
	/**
	 * Index for the basics question
	 */
	basicsIndex: number;
	/**
	 * Index for the times question
	 */
	timesIndex: number;
}

export class JoinSurveyModel extends SurveyClassModel {
	@observable
	public emailIndex: number;
	@observable
	public basicsIndex: number;
	@observable
	public timesIndex: number;

	constructor(params: IJoinSurveyModelParams) {
		super(params);
		makeObservable(this);
		this.emailIndex = params.emailIndex;
		this.basicsIndex = params.basicsIndex;
		this.timesIndex = params.timesIndex;
	}

	/**
	 * Returns all questions from this join form
	 */
	public get questions(): IAbstractQuestionModel[] {
		// get an array of our dynamic questions
		const questions: IAbstractQuestionModel[] = Array.from(
			this.questionMap.values()
		);
		// insert our static questions
		questions.push(
			...[
				new StaticQuestionModel({
					index: this.emailIndex,
					type: StaticQuestionEnum.EMAIL
				}),
				new StaticQuestionModel({
					index: this.basicsIndex,
					type: StaticQuestionEnum.BASICS
				}),
				new StaticQuestionModel({
					index: this.timesIndex,
					type: StaticQuestionEnum.TIMES
				})
			]
		);
		// sort the questions
		questions?.sort((a, b) => a.index - b.index);

		return questions;
	}

	/**
	 * Map of all conditional logic for this survey
	 */
	@computed
	public get conditionalLogicMap(): ObservableMap<
		number,
		ObservableMap<number, number>
	> {
		return this.dynamicQuestions.reduce(
			(map, question: IDynamicQuestionModel) => {
				if (question.conditionalLogic?.length) {
					map.set(
						question.id,
						question.conditionalLogic.reduce(
							(innerMap, logic: ConditionalLogicModel) => {
								innerMap.set(logic.aid, logic.target);
								return innerMap;
							},
							new ObservableMap<number, number>()
						)
					);
				}
				return map;
			},
			new ObservableMap<number, ObservableMap<number, number>>()
		);
	}

	/**
	 * List of questions that should be hidden from the user based on conditional logic and their answers
	 */
	@computed
	public get hiddenQuestions(): number[] {
		// create number array for tracking
		const indicesToHide: number[] = [];
		// iterate survey logic
		this.conditionalLogicMap.forEach(
			(logic: Map<number, number>, qid: number) => {
				// find the question from the logic
				const question: IDynamicQuestionModel = this.dynamicQuestions.find(
					(ques: IDynamicQuestionModel) => ques.id === qid
				);
				// if intentional, multi, or radio
				if (
					question.type !== SurveyQuestionTypeEnum.Freetext &&
					question.type !== SurveyQuestionTypeEnum.Likert
				) {
					// parse question as multi select
					const parsed: IMultiSelectSurveyQuestionModel = question as IMultiSelectSurveyQuestionModel;
					if (parsed.selectedOptionId) {
						// if radio
						if (logic.has(parsed.selectedOptionId)) {
							// Test if the rule is "End of Survey"
							if (!logic.get(parsed.selectedOptionId)) {
								let idx = question.index + 1;
								let lastIndex = Math.max(
									...this.dynamicQuestions.map(
										(question) => question.index
									)
								);

								while (idx <= lastIndex) {
									indicesToHide.push(idx);
									idx++;
								}
							}
							// find target question
							let targetQuestion: IDynamicQuestionModel = this.dynamicQuestions.find(
								(ques: IDynamicQuestionModel) =>
									ques.id ===
									logic.get(parsed.selectedOptionId)
							);
							// ensure target question exists and that there are indices to hide
							if (
								targetQuestion &&
								targetQuestion.index > question.index + 1
							) {
								// iterate and add indices to hide
								let idx = question.index + 1;
								while (idx < targetQuestion.index) {
									indicesToHide.push(idx);
									idx++;
								}
							}
						}
					} else {
						// if multiple answers
						// track the lowest index
						let lowestIndex: number;
						let endOfSurvey: boolean = false;
						// iterate all selected answers
						parsed.selectedAnswers?.forEach((aid: number) => {
							if (logic.has(aid)) {
								// Test if the rule is "End of Survey"
								if (!logic.get(aid)) {
									endOfSurvey = true;
								}
								// find target question
								let targetQuestion: IDynamicQuestionModel = this.dynamicQuestions.find(
									(ques: IDynamicQuestionModel) =>
										ques.id === logic.get(aid)
								);
								// ensure target question exists and that there are indices to hide
								if (
									targetQuestion &&
									targetQuestion.index > question.index + 1
								) {
									// find the lower index that is conditionally pointed to
									if (
										!lowestIndex ||
										lowestIndex > targetQuestion.index
									) {
										lowestIndex = targetQuestion.index;
									}
								}
							}
						});

						// If there is a conditional path that goes to the end of the survey, and there are no non-end-survey conditional logics
						if (!lowestIndex && endOfSurvey) {
							let idx = question.index + 1;
							let lastIndex = Math.max(
								...this.dynamicQuestions.map(
									(question) => question.index
								)
							);

							while (idx <= lastIndex) {
								indicesToHide.push(idx);
								idx++;
							}
						}

						// if a conditional path exists
						if (lowestIndex) {
							// iterate and add indices to hide
							let idx = question.index + 1;
							while (idx < lowestIndex) {
								indicesToHide.push(idx);
								idx++;
							}
						}
					}
				}
			}
		);
		return indicesToHide;
	}

	/**
	 * Returns only dynamic questions from this join form
	 */
	public get dynamicQuestions(): IDynamicQuestionModel[] {
		return this.questions.filter(
			(question) => question instanceof DynamicQuestionModel
		) as IDynamicQuestionModel[];
	}

	@computed
	public get dynamicQuestionsForProfile(): IDynamicQuestionModel[] {
		return this.dynamicQuestions?.filter(
			(question: IDynamicQuestionModel) =>
				!this.hiddenQuestions.includes(question.index) &&
				[
					QuestionVisibilityEnum.VISIBLE,
					QuestionVisibilityEnum.PROFILE
				].includes(question.visibility)
		);
	}

	/**
	 * Assign responses from a user to a survey
	 * @param responses
	 */
	public assignResponses(
		responses: Map<number, SurveyQuestionResponse>
	): void {
		// iterate all questions
		this.dynamicQuestions.forEach((question: IDynamicQuestionModel) => {
			// get the response for this question
			const response: SurveyQuestionResponse = responses?.get(
				question.id
			);
			if (response) {
				// set answers
				if (response.selectedOptions) {
					response.selectedOptions.forEach((option: number) => {
						if (question.type === SurveyQuestionTypeEnum.Radio) {
							(question as IRadioSurveyQuestionModel).selectOption(
								option
							);
						} else if (
							question.type === SurveyQuestionTypeEnum.Multiselect
						) {
							(question as IMultiSelectSurveyQuestionModel).selectOption(
								option,
								true
							);
						} else if (question.type === SurveyQuestionTypeEnum.Intentional) {
							(question as IIntentionalSurveyQuestionModel).selectOption(
								option,
								true
							);
						}
					});
				} else if (response.freetextResponse) {
					(question as IFreetextSurveyQuestionModel).setAnswer(
						response.freetextResponse
					);
				} else if (response.likertValue) {
					(question as ILikertSurveyQuestionModel).setValue(
						response.likertValue
					);
				}
				// set weight
				if (response.weight) {
					question.modifyLocalAlgoWeight(response.weight);
				}
			}
		});
	}

	public editSurveyQuestion(qid: number, params: UpdateQuestionPutRequest) {
		// get the question from the params
		return super.editSurveyQuestion(qid, params).pipe(
			map((data: UpdateQuestionPutResponse) => {
				if (data.basicsIndex) {
					this.basicsIndex = data.basicsIndex;
				}
				if (data.emailIndex) {
					this.emailIndex = data.emailIndex;
				}
				if (data.timeIndex) {
					this.timesIndex = data.timeIndex;
				}
				return data;
			})
		);
	}

	/**
	 * Get a read only version of a members survey
	 * @param responses
	 */
	public getReadOnlySurvey(
		responses: Map<number, SurveyQuestionResponse>
	): ReadOnlyAnswer[] {
		// create result
		const result: ReadOnlyAnswer[] = [];
		// get dynamic questions
		this.dynamicQuestions.forEach((question: IDynamicQuestionModel) => {
			// get the response for this question
			const response: SurveyQuestionResponse = responses?.get(
				question.id
			);
			if (response) {
				if (response.selectedOptions) {
					result.push({
						question: question.title,
						answer: question.answers
							.filter((val: ISurveyAnswerOptionModel) =>
								response.selectedOptions.includes(val.id)
							)
							.map((val: ISurveyAnswerOptionModel) => val.value)
							.join(', ')
					});
				} else if (response.freetextResponse) {
					result.push({
						question: question.title,
						answer: response.freetextResponse
					});
				} else if (response.likertValue) {
					result.push({
						question: question.title,
						answer: (question as ILikertSurveyQuestionModel).getLabel(
							response.likertValue
						)
					});
				}
			}
		});
		return result;
	}

	/**
	 * Moves a static question within the join form
	 * @param id
	 * @param index
	 * @returns
	 */
	public moveStaticQuestion(id: FixedSlidesEnum, index: number) {
		return RestApiClient.serviceRequest({
			generator: () =>
				QuestionsService.updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						surveyType: SurveyTypes.PROFILE,
						qid: id,
						requestBody: {
							index: index,
							fixedSlideId: id
						}
					}
				)
		}).pipe(
			map((data: UpdateQuestionPutResponse) => {
				if (data.basicsIndex || data.basicsIndex === 0) {
					this.basicsIndex = data.basicsIndex;
				}
				if (data.emailIndex || data.emailIndex === 0) {
					this.emailIndex = data.emailIndex;
				}
				if (data.timeIndex || data.timeIndex === 0) {
					this.timesIndex = data.timeIndex;
				}
				this.setSurveyData({
					locked: false,
					questions: Object.values(data.questions)
				});
			})
		) as Observable<void>;
	}
}
